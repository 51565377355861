import Cookies from "js-cookie";
import CookieConsent from "@grrr/cookie-consent";
import { trapFocus } from "@grrr/utils";
import { takeLast } from "@grrr/utils";
import { pushEvent } from "./gtm-event";
import { waitForGlobal } from "./util";
import { subscribe } from "./observer";
import { newsletterFormIsMounted } from "./observer-subjects";

const COOKIE_CONSENT_COOKIE_NAME = "toc_cookie_consent";
const OVERLAY_ACTIVE_CLASS = "page-has-active-overlay";
// Global var needed to store the focus trap instance.
let focusTrap = null;

/**
 * Focus trap
 */
const trapTheFocus = (element) => {
  focusTrap = trapFocus(element.firstElementChild);
};
const releaseFocus = () => {
  if (focusTrap) {
    focusTrap.release();
  }
};

/**
 * Show Cookie overlay
 */
const showCookieOverlay = () => {
  const cookieOverlay = document.querySelector(".js-cookie-consent-overlay");
  cookieOverlay.setAttribute("data-hidden", "false");
  cookieOverlay.setAttribute("aria-hidden", "false");

  // Only add the overlay class if the overlay is a modal to block scrolling and trap focus.
  if (cookieOverlay.dataset.isModal === 'true') {
    document.documentElement.classList.toggle(OVERLAY_ACTIVE_CLASS, true);

    trapTheFocus(cookieOverlay);
    // The NewsletterForm is added to the DOM after the focusTrap is set
    // The focusTrap gets set again after the Newsletter is mounted to exclude the Newsletter
    subscribe(newsletterFormIsMounted, () => {
      releaseFocus();
      trapTheFocus(cookieOverlay);
    });
  }
};

/**
 * Hide Cookie overlay
 */
const hideCookieOverlay = () => {
  const cookieOverlay = document.querySelector(".js-cookie-consent-overlay");
  cookieOverlay.setAttribute("data-hidden", "true");
  cookieOverlay.setAttribute("aria-hidden", "true");
  document.documentElement.classList.toggle(OVERLAY_ACTIVE_CLASS, false);
  releaseFocus();
};

/**
 * Remove aria attributes of CookieDialog
 * Because they should be placed of the parent
 */
const removeAriaAttributes = (element) => {
  // These attributes are on the parent element
  element.removeAttribute("aria-live");
  element.removeAttribute("aria-describedby");
  element.removeAttribute("aria-hidden");
  element.removeAttribute("role");
  element.removeAttribute("tabindex");
};

/**
 * Add aria attributes to the overlay
 * To match with the current active content
 */
const changeOverlayAriaAttributes = (ariaElement, newAriaDescriptionId) => {
  ariaElement.setAttribute("aria-describedby", newAriaDescriptionId);
  ariaElement.removeAttribute("aria-labelledby");
};

/**
 * Set focus to the first found element that matches the selector.
 */
const setFocusOnFirstFocusableElement = (container) => {
  const allFocusableElements = container.querySelectorAll(
    "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe",
  );
  const firstFocusableElement = allFocusableElements[0];

  if (!firstFocusableElement) return;
  firstFocusableElement.focus();
};

/**
 * Called by listener.
 * Update Tag Manager.
 */
const handleUpdate = (cookies) => {
  const optimalCookiesAreAccepted = !!cookies.find(
    (cookie) => cookie.id === "optimal" && cookie.accepted,
  );

  // Send accepted cookies.
  pushEvent({
    event: "cookieConsent",
    cookieConsent: optimalCookiesAreAccepted ? "optimal" : "strictly_necessary",
  });

  // Set actual cookie to share consent-level with subdomain.
  const rootDomain = takeLast(2, window.location.hostname.split(".")).join(".");
  Cookies.set(
    COOKIE_CONSENT_COOKIE_NAME,
    `${optimalCookiesAreAccepted ? "optimal" : "strictly_necessary"}`,
    {
      expires: 365,
      domain: rootDomain,
      secure: window.location.protocol === "https:",
    },
  );

  hideCookieOverlay();
};

const initiateCookieConsentPrompt = () => {
  // An optional cookie might be present from the subdomain.
  // If so, settings in the cookie are leading, and we will manipulate
  // localStorage before initializing the CookieConsent module.
  const existingCookieChoice = Cookies.get(COOKIE_CONSENT_COOKIE_NAME);
  const cookieChoiceIsValid = window.COOKIE_CONSENT_CONFIG.cookies.find(
    (cookieOption) => cookieOption.id === existingCookieChoice,
  );
  const optimalCookiesAreAccepted = existingCookieChoice === "optimal";

  if (cookieChoiceIsValid && window.localStorage) {
    localStorage.setItem(
      "cookie-consent-preferences",
      JSON.stringify(
        window.COOKIE_CONSENT_CONFIG.cookies.map((cookie) => ({
          id: cookie.id,
          accepted: optimalCookiesAreAccepted
            ? true
            : cookie.id === existingCookieChoice,
        })),
      ),
    );
  }

  // Construct and initialize the module.
  const cookieConsent = CookieConsent(window.COOKIE_CONSENT_CONFIG);

  // Update Tag Manager when `update` event is fired.
  cookieConsent.on("update", handleUpdate);

  // Make the object globally available.
  window.CookieConsent = cookieConsent;

  // If there are no cookies in LocalStorage show cookie consent
  if (!localStorage.getItem("cookie-consent-preferences")) {
    showCookieOverlay();
  }
};

/**
 * This function automatically accepts cookies for visitors that are from
 * the US.
 * It will avoid the GeoIP lookup if existing preferences are in localStorage.
 */
const acceptAllCookiesForUsVisitors = () => {
  return new Promise((resolve, reject) => {
    // If existing preferences are found, resolve immediately to avoid the HTTP
    // request.
    if (localStorage.getItem("cookie-consent-preferences")) {
      resolve();
      return;
    }
    waitForGlobal("geoip2")
      .then(() => {
        geoip2.country((response) => {
          if (response.country.iso_code === "US") {
            // If the visitor is from the US, simply accept the
            // "optimal" choice.
            localStorage.setItem(
              "cookie-consent-preferences",
              JSON.stringify(
                window.COOKIE_CONSENT_CONFIG.cookies.map((cookie) => ({
                  id: cookie.id,
                  accepted: true,
                })),
              ),
            );
          }
          resolve();
        }, reject);
      })
      .catch(reject);
  });
};

/**
 * Accept the optimal cookies
 */
const acceptAllCookies = () => {
  // Update existing cookies to ensure optimal cookies are set.
  const { cookies } = window.COOKIE_CONSENT_CONFIG;

  const newCookies = cookies.map((item) => ({
    ...item,
    accepted: true,
  }));

  // Set cookies programmatically.
  window.CookieConsent.updatePreference(newCookies);
  window.CookieConsent.hideDialog();
};

/**
 * ExecuteOnReady
 */
export default () => {
  // Bail out if there is no config.
  if (!window.COOKIE_CONSENT_CONFIG) {
    return;
  }

  acceptAllCookiesForUsVisitors()
    .then(() => {
      initiateCookieConsentPrompt();
    })
    .catch((err) => {
      console.error(err);
      initiateCookieConsentPrompt();
    });
};

/**
 * Handlers
 */
export const acceptAllCookiesHandler = (element, e) => {
  e.preventDefault();

  // Bail out if there is no config.
  if (!window.COOKIE_CONSENT_CONFIG) {
    return;
  }
  acceptAllCookies();
};

/**
 * Select Cookie Preference is responsible for showing the various cookie types
 * so visitors can select individual preferences.
 */
export const selectCookiePreferenceHandler = (element) => {
  const cookieOverlay = document.querySelector(".js-cookie-consent-overlay");
  const cookieOverlayContent = document.querySelector(
    ".js-cookie-consent-overlay__content",
  );
  const cookieDialogModule = window.CookieConsent.getDialog();

  // Remove existing aria attributes of cookieDialogModule
  removeAriaAttributes(cookieDialogModule);
  // Calls CookieConsent module and places it inside cookie-consent-overlay popup
  cookieOverlay.appendChild(cookieDialogModule);
  // Hide the current section within the modal
  cookieOverlayContent.setAttribute("data-hidden", "true");

  const cookieDescription = document.querySelector(
    ".js-cookie-bar .cookie-consent__header",
  );

  changeOverlayAriaAttributes(cookieOverlay, cookieDescription.id);
  setFocusOnFirstFocusableElement(cookieDialogModule);
};
